
//公共库
import axios from "axios";
import { VueEditor } from "vue2-editor";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { parseTime } from "@/utils";
import DTCls from "@/utils/datetime";
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { getAppBaseApi, getHeaders } from "@/api/request";
import { getInnerTestList, setInnerTestStatus, saveInnerTest, getInnerTestUserList, exportInnerTestUserList, auditInnerTestUser, auditInnerTestReport } from "@/api/request/tsak";

//组件
@Component({
  name: "TaskBeta",
  components: {
    VueEditor,
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //请求数据
  private listQuery: any = {
    //页码
    page: 1,
    page_size: 20,

    //临时数据
    time: [],

    //数据
    name: "",
    status: -1,
    end_time: "",
    begin_time: "",
  };

  //时间选择器参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //类型列表
  private statusList: any[] = [
    { id: -1, name: "全部" },
    { id: 0, name: "草稿" },
    { id: 1, name: "启用" },
    { id: 2, name: "停用" },
    { id: 3, name: "删除" },
  ];

  //定义变量
  private list: any[] = []; //列表
  private total: number = 0; //总数
  private listLoading: boolean = false; // 列表加载

  //创建调用
  created() {
    //获取列表
    this.getList();
  }

  // //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //时间赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.end_time = this.listQuery.time[1];
      this.listQuery.begin_time = this.listQuery.time[0];
    } else {
      this.listQuery.end_time = "";
      this.listQuery.begin_time = "";
    }

    //获取数据
    const { data } = await getInnerTestList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理新增
  private addHandler(): void {
    //数据赋值
    this.form = {
      id: 0, // 任务ID
      name: "", // 任务名称
      content: "", // 任务内容
      max_user: 10, // 最大参与用户数
      expire_time: "", //到期时间，具体到时刻
    };

    //显示界面
    this.dialogVisible = true;
  }

  //处理查询
  private handleSearch(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理编辑
  private handleEdit(row: any): void {
    //数据赋值
    this.form = {
      id: row.id, // 任务ID
      name: row.name, // 任务名称
      content: row.content, // 任务内容
      max_user: row.max_user, // 最大参与用户数
      expire_time: row.expire_time, //到期时间，具体到时刻
    };

    //显示界面
    this.dialogVisible = true;
  }

  //获取时间
  private getTimeStr(time: number): any {
    return parseTime(time * 1000);
  }

  //用户列表
  private handleUserList(row: any): void {
    //数据赋值
    this.userListQuery = {
      //页码
      page: 1,
      page_size: 20,

      //临时数据
      user_id_str: "", //用户昵称

      //数据
      task_id: row.id, //任务ID
      user_id: 0, //用户ID
      nick_name: "", //用户昵称

      //状态数据
      status: -1, //用户状态：-1-全部，0-待审核，1-审核通过，2-审核不通过
      submit_status: -1, //报告提交状态：-1-全部，0-未提交，1-已提交
      audit_status: -1, //报告审核状态：-1-全部，0-待审核，1-审核通过，2-审核不通过
    };

    //获取用户列表
    this.getUserList();

    //显示用户列表
    this.userDialogVisible = true;
  }

  //处理状态
  private async handleStatus(row: any, status: number) {
    //显示等待
    this.listLoading = true;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 2 * 1000);

    //获取数据
    await setInnerTestStatus({ id: row.id, type: 1, status: status });

    //获取列表
    this.getList();
  }

  //处理测试状态
  private async handleRunStatus(row: any, status: number) {
    //显示等待
    this.listLoading = true;

    //获取数据
    await setInnerTestStatus({ id: row.id, type: 3, status: status });

    //获取列表
    this.getList();
  }

  //处理报名状态
  private async handleApplyStatus(row: any, status: number) {
    //显示等待
    this.listLoading = true;

    //获取数据
    await setInnerTestStatus({ id: row.id, type: 2, status: status });

    //获取列表
    this.getList();
  }

  //-------------------------------- 添加/编辑 任务 ------------------------------
  //定义变量
  private getHeads = getHeaders();
  private getAction = this.getApi();
  private dialogVisible: boolean = false;
  private dialogLoading: boolean = false;

  //表单数据
  private form: any = {
    id: 0, // 任务ID
    name: "", // 任务名称
    content: "", // 任务内容
    max_user: 10, // 最大参与用户数
    expire_time: "", //到期时间，具体到时刻
  };

  //获取API
  private getApi(): string {
    const reg = /^https?\:\/\//;
    const baseApi = getAppBaseApi();
    const isMatch = reg.test(baseApi);
    const prefix = !isMatch ? window.location.origin : "";
    return `${prefix}${baseApi}/adm/picture/files`;
  }

  //取消按钮
  private btnCancel(): void {
    //隐藏界面
    this.dialogVisible = false;
  }

  //保存按钮
  private async handlePush() {
    //数据处理
    if (this.form.name == "") return this.$message.error("请输入任务名称");
    if (this.form.content == "") return this.$message.error("请输入任务内容");
    if (this.form.expire_time == "") return this.$message.error("请选择到期时间");
    if (Number(this.form.max_user) == 0) return this.$message.error("最大参与用户数 > 0");

    //显示等待
    this.dialogLoading = true;

    //数据赋值
    this.form.max_user = Number(this.form.max_user);

    //保存任务
    await saveInnerTest(this.form);

    //隐藏等待
    setTimeout(() => {
      //隐藏加载
      this.dialogLoading = false;

      //隐藏对话框
      this.dialogVisible = false;

      //加载列表
      this.getList();
    }, 0.5 * 1000);
  }

  //处理图片添加
  private async handleImageAdded(file: any, Editor: any, cursorLocation: any, resetUploader: Function) {
    //数据处理
    const formData = new FormData();
    formData.append("files", file);

    //上传图片
    var config: any = {};
    config.headers = this.getHeads;
    const res = await axios.post(this.getAction, formData, config);

    //数据赋值
    let url: string = res.data.data.images[0];

    //插入图片
    Editor.insertEmbed(cursorLocation, "image", url);

    //刷新编辑器
    resetUploader();
  }

  //-------------------------------- 用户列表 ------------------------------
  //定义变量
  private userList: any[] = []; //列表
  private userTotal: number = 0; //总数
  private userDialogVisible: boolean = false;
  private userDialogLoading: boolean = false;

  //请求数据
  private userListQuery: any = {
    //页码
    page: 1,
    page_size: 20,

    //临时数据
    user_id_str: "", //用户昵称

    //数据
    task_id: 0, //任务ID
    user_id: 0, //用户ID
    nick_name: "", //用户昵称

    //状态数据
    status: -1, //用户状态：-1-全部，0-待审核，1-审核通过，2-审核不通过
    submit_status: -1, //报告提交状态：-1-全部，0-未提交，1-已提交
    audit_status: -1, //报告审核状态：-1-全部，0-待审核，1-审核通过，2-审核不通过
  };

  //用户状态列表
  private userStatusList: any[] = [
    { id: -1, name: "全部用户状态" },
    { id: 0, name: "待审核" },
    { id: 1, name: "审核通过" },
    { id: 2, name: "审核不通过" },
  ];

  //报告提交状态
  private submitStatusList: any[] = [
    { id: -1, name: "全部报告提交状态" },
    { id: 0, name: "未提交" },
    { id: 1, name: "已提交" },
  ];

  //报告审核状态
  private auditStatusList: any[] = [
    { id: -1, name: "全部报告审核状态" },
    { id: 0, name: "待审核" },
    { id: 1, name: "审核通过" },
    { id: 2, name: "审核不通过" },
  ];

  //取消按钮
  private btnUserCancel(): void {
    //隐藏界面
    this.userDialogVisible = false;
  }

  //获取用户列表
  private async getUserList() {
    //显示等待
    this.userDialogLoading = true;

    //数据赋值
    this.userListQuery.user_id = Number(this.userListQuery.user_id_str);

    //获取数据
    const { data } = await getInnerTestUserList(this.userListQuery);

    //数据赋值
    this.userList = data.list; //列表
    this.userTotal = data.total; //总数

    //隐藏等待
    setTimeout(() => {
      this.userDialogLoading = false;
    }, 0.5 * 1000);
  }

  //处理导出
  private async exportHandler() {
    //显示等待
    this.userDialogLoading = true;

    //获取数据
    const { data } = await exportInnerTestUserList(this.userListQuery);

    //保存数据
    saveAs(data, "用户列表");

    //隐藏等待
    setTimeout(() => {
      this.userDialogLoading = false;
    }, 0.5 * 1000);
  }

  //处理用户查询
  private handleUserSearch(): void {
    //数据赋值
    this.userListQuery.page = 1;

    //获取列表
    this.getUserList();
  }

  //处理用户状态
  private async handleUserStatus(row: any, status: number) {
    //显示等待
    this.userDialogLoading = true;

    //处理用户状态
    await auditInnerTestUser({ id: row.id, status: status, remark: row.remark });

    //获取列表
    this.getUserList();
  }

  //处理报告状态
  private async handleSubmitStatus(row: any, status: number) {
    //显示等待
    this.userDialogLoading = true;

    //处理用户状态
    await auditInnerTestReport({ id: row.id, status: status, remark: row.audit_remark });

    //获取列表
    this.getUserList();
  }
}
