import axios from "axios";

//本地导入
import request, { getAppBaseApi, getHeaders } from "../request";

//每日签到任务
export const getTaskDailyList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/task/daily/list",
  });

//编辑每日签到
export const editTaskDaily = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/task/daily/edit",
  });

//新手任务
export const getTaskNewbieList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/task/newbie/list",
  });

//新增/编辑新手任务
export const editTaskNewbie = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/task/newbie/edit",
  });

//获取任务列表
export const getInnerTestList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/inner/test/list",
  });

//设置状态
export const setInnerTestStatus = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/inner/test/status/set",
  });

//添加/更新任务
export const saveInnerTest = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/inner/test/save",
  });

//用户列表
export const getInnerTestUserList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/inner/test/user/list",
  });

//导出用户列表
export const exportInnerTestUserList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/inner/test/user/export`,
  });

//用户审核
export const auditInnerTestUser = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/inner/test/user/audit",
  });

//报告审核
export const auditInnerTestReport = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/inner/test/report/audit",
  });
